// Require Default dependencies
window.$ = window.jQuery = require('jquery');

$.fn.extend({
  animateCss: function(animationName) {
    var deferred = $.Deferred();
    var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
    this.addClass('animated ' + animationName).one(animationEnd, function() {
      deferred.resolve();
      $(this).removeClass('animated ' + animationName);
    });
    return deferred.promise();
  }
});
